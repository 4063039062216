import "../../common/polyfills";
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import TasMembershipForm, { ITasMembershiFormProps } from './Components/TasMembershipForm';
import { StripeProvider, Elements } from 'react-stripe-elements';
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { ReactStripeElements } from "react-stripe-elements";

function MountTasMembershipForm(props: ITasMembershiFormProps, el: Element) {
    ReactDOM.render(
        <GoogleReCaptchaProvider reCaptchaKey={props.reCaptchaSiteKey}>
            <StripeProvider apiKey={props.publishableKey}>
                <Elements>
                    <TasMembershipForm {...props} />
                </Elements>
            </StripeProvider>
        </GoogleReCaptchaProvider>
        , el);
}

(window as any).MountTasMembershipForm = MountTasMembershipForm;


