import { MembershipFormType } from "../../WaMembershipForm/Components/Constants";

export const MembershipFormConstants : ITasMembershipFormConstants = {
    amount: "Amount",
    firstName: "FirstName",
    lastName: "LastName",
    address: "Address",
    suburb: "Suburb",
    postcode: "Postcode",
    email: "Email",
    comments: "Comments",
    state: "State",
    numberOfTickets: "NumberOfTickets",
    listOfExtraFields: "listOfExtraFields",
    membershipType: "MembershipType",
    membershipCost: "MembershipCost",
    mobile: "Mobile",
    referral: "Referral",
    occupation: "Occupation",
    union: "Union",
    indigenous: "Indigenous",
    pledge: "Pledge",
    dateOfBirth: "DateOfBirth",
    citizen: "Citizen",
    yearlyPayment: "YearlyPayment",
    interestedInRainbow: "interestedInRainbow",
    interestedInLEAN: "interestedInLEAN",
    interestedInEnabled: "interestedInEnabled",
    interestedInForChoice: "interestedInForChoice",
    interestedInPalestine: "interestedInPalestine",
    interestedInWiseUseOfResources: "interestedInWiseUseOfResources",
    interestedInRegionalTas: "interestedInRegionalTas",
    interestedInLocalGovernment: "interestedInLocalGovernment",
    interestedInCivilPoliticalRights: "interestedInCivilPoliticalRights",
    interestedInTradeUnion: "interestedInTradeUnion",
    donationInterval: "DonationInterval",
    renewal: "Renewal",
};

export interface ITasMembershipFormConstants {
    amount: string
    firstName: string
    lastName: string
    address: string
    suburb: string
    postcode: string
    email: string
    comments: string
    state: string
    numberOfTickets: string
    listOfExtraFields: string
    membershipType: string
    membershipCost: string
    mobile: string
    referral: string
    occupation: string
    union: string
    indigenous: string
    pledge: string
    dateOfBirth: string
    citizen: string
    yearlyPayment: string
    interestedInRainbow: string
    interestedInLEAN: string
    interestedInEnabled: string
    interestedInForChoice: string
    interestedInPalestine: string
    interestedInWiseUseOfResources: string
    interestedInRegionalTas: string
    interestedInLocalGovernment: string
    interestedInCivilPoliticalRights: string
    interestedInTradeUnion: string
    donationInterval: string
    renewal: string
}

export const States = [
    {
        value: "",
        text: "Select State"
    },
    {
        value: "NSW",
        text: "New South Wales"
    },
    {
        value: "VIC",
        text: "Victoria"
    },
    {
        value: "QLD",
        text: "Queensland"
    },
    {
        value: "SA",
        text: "South Australia"
    },
    {
        value: "WA",
        text: "Western Australia"
    },
    {
        value: "TAS",
        text: "Tasmania"
    },
    {
        value: "NT",
        text: "Northern Territory"
    },
    {
        value: "ACT",
        text: "Australian Capital Territory"
    }
];

export const Unions = [
    {
        value: "",
        text: "Select affiliated union"
    },
    {
        value: "AMWU",
        text: "AMWU"
    },
    {
        value: "ASU",
        text: "ASU"
    },
    {
        value: "AWU",
        text: "AWU"
    },
    {
        value: "CEPU",
        text: "CEPU"
    },
    {
        value: "CFMEU CG",
        text: "CFMEU CG"
    },
    {
        value: "CFMEU Manufacturing",
        text: "CFMEU Manufacturing"
    },
    {
        value: "CPSU – PSU",
        text: "CPSU – PSU"
    },
    {
        value: "MUA",
        text: "MUA"
    },
    {
        value: "RTBU",
        text: "RTBU"
    },
    {
        value: "SDA",
        text: "SDA"
    },
    {
        value: "TWU",
        text: "TWU"
    },
    {
        value: "UFU",
        text: "UFU"
    },
    {
        value: "UWU",
        text: "UWU"
    },
];

export const MembershipTypes = [
    {
        value: 0,
        text: "Please Select",
        key: ""
    },
    {
        value: 1.50,
        text: "Under 21 ($1.50)",
        key: "M-Under21"
    },
    {
        value: 2.50,
        text: "Concession ($2.50)",
        key: "M-Concession"
    },
    {
        value: 7,
        text: "Earning $0-$50,000 Affiliated Union ($7)",
        key: "M-Earning-0-50000-Union"
    },
    {
        value: 7.50,
        text: "Earning $0-$50,000 ($7.50)",
        key: "M-Earning-0-50000"
    },
    {
        value: 10.00,
        text: "Earning $50,000-$80,000 Affiliated Union ($10.00)",
        key: "M-Earning-50000-80000-Union"
    },
    {
        value: 12.00,
        text: "Earning $50,000-$80,000 ($12.00)",
        key: "M-Earning-50000-80000"
    },
    {
        value: 15.50,
        text: "Earning $80,000-$120,000 Affiliated Union ($15.50)",
        key: "M-Earning-80000-120000-Union"
    },
    {
        value: 18.50,
        text: "Earning $80,000-$120,000 ($18.50)",
        key: "M-Earning-80000-120000"
    },
    {
        value: 25,
        text: "Earning $120,000 and above ($25.00)",
        key: "M-Earning-120000-and-above"
    },
    {
        value: 18.00,
        text: "Under 21 ($18.00)",
        key: "Y-Under21"
    },
    {
        value: 30.00,
        text: "Concession ($30.00)",
        key: "Y-Concession"
    },
    {
        value: 84.0,
        text: "Earning $0-$50,000 Affiliated Union ($84.00)",
        key: "Y-Earning-0-50000-Union"
    },
    {
        value: 90.00,
        text: "Earning $0-$50,000 ($90.00)",
        key: "Y-Earning-0-50000"
    },
    {
        value: 120.00,
        text: "Earning $50,000-$80,000 Affiliated Union ($120.00)",
        key: "Y-Earning-50000-80000-Union"
    },
    {
        value: 144.00,
        text: "Earning $50,000-$80,000 ($144.00)",
        key: "Y-Earning-50000-80000"
    },
    {
        value: 186.00,
        text: "Earning $80,000-$120,000 Affiliated Union ($186.00)",
        key: "Y-Earning-80000-120000-Union"
    },
    {
        value: 222.00,
        text: "Earning $80,000-$120,000 ($222.00)",
        key: "Y-Earning-80000-120000"
    },
    {
        value: 300.00,
        text: "Earning $120,000 and above ($300.00)",
        key: "Y-Earning-120000-and-above"
    },
];

export const TasMembershipInitialValuesFactory = (membershipFormType: MembershipFormType)=> {
    switch (membershipFormType) {
        case MembershipFormType.Renew :
            return MembershipInitialValuesBase
        case MembershipFormType.Join:
            return {...JoinMembershipExtraInitialValues, ...MembershipInitialValuesBase}
    }
}

const MembershipInitialValuesBase = {
    [MembershipFormConstants.yearlyPayment]: false,
    [MembershipFormConstants.firstName]: "",
    [MembershipFormConstants.lastName]: "",
    [MembershipFormConstants.address]: "",
    [MembershipFormConstants.suburb]: "",
    [MembershipFormConstants.postcode]: "",
    [MembershipFormConstants.mobile]: "",
    [MembershipFormConstants.email]: "",
    [MembershipFormConstants.state]: "",
    [MembershipFormConstants.amount]: 5,
    [MembershipFormConstants.referral]: "",
    [MembershipFormConstants.occupation]: "",
    [MembershipFormConstants.union]: "",
    [MembershipFormConstants.indigenous]: "no",
    [MembershipFormConstants.pledge]: false,
    [MembershipFormConstants.dateOfBirth]: "",
    [MembershipFormConstants.citizen]: "yes",
    [MembershipFormConstants.interestedInRainbow]: false,
    [MembershipFormConstants.interestedInLEAN]: false,
    [MembershipFormConstants.interestedInEnabled]: false,
    [MembershipFormConstants.interestedInForChoice]: false,
    [MembershipFormConstants.interestedInPalestine]: false,
    [MembershipFormConstants.interestedInWiseUseOfResources]: false,
    [MembershipFormConstants.interestedInRegionalTas]: false,
    [MembershipFormConstants.interestedInLocalGovernment]: false,
    [MembershipFormConstants.interestedInCivilPoliticalRights]: false,
    [MembershipFormConstants.interestedInTradeUnion]: false,
    [MembershipFormConstants.donationInterval]: "one"
};

const JoinMembershipExtraInitialValues = {
    [MembershipFormConstants.referral]: "",
    [MembershipFormConstants.occupation]: "",
    [MembershipFormConstants.union]: "",
    [MembershipFormConstants.indigenous]: false,
    [MembershipFormConstants.dateOfBirth]: "",
}