import * as React from "react";
import FormField from "./FormField";
import { MembershipFormConstants, States, ITasMembershipFormConstants } from "./Constants";
import { Field, FormikErrors, FormikTouched } from "formik";
import DatePicker from "react-datepicker";
import "../Style/datePicker.scss";
import * as moment from "moment";
import { useContext } from "react";
import { ctxt } from "./TasMembershipForm";


interface IStep1Props {
    animClass: string;
    errors: FormikErrors<ITasMembershipFormConstants>
    touched: FormikTouched<ITasMembershipFormConstants>;
    values: ITasMembershipFormConstants;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
    setStepNum: (step: number) => void;
    setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void;
    isRenewal: boolean;
}

export default (props: IStep1Props) => {
    let touchFields = () => {
        props.setFieldTouched(MembershipFormConstants.firstName, true, true);
        props.setFieldTouched(MembershipFormConstants.lastName, true, true);
        props.setFieldTouched(MembershipFormConstants.email, true, true);
        props.setFieldTouched(MembershipFormConstants.mobile, true, true);
        props.setFieldTouched(MembershipFormConstants.dateOfBirth, true, true);
        props.setFieldTouched(MembershipFormConstants.occupation, true, true);
        props.setFieldTouched(MembershipFormConstants.address, true, true);
        props.setFieldTouched(MembershipFormConstants.suburb, true, true);
        props.setFieldTouched(MembershipFormConstants.postcode, true, true);
        props.setFieldTouched(MembershipFormConstants.state, true, true);
        props.setFieldTouched(MembershipFormConstants.pledge, true, true);
    }
    let continueClickHandler = () => {
        if ((!props.errors[MembershipFormConstants.firstName] && props.touched[MembershipFormConstants.firstName]) &&
            (!props.errors[MembershipFormConstants.lastName] && props.touched[MembershipFormConstants.lastName]) &&
            (!props.errors[MembershipFormConstants.email] && props.touched[MembershipFormConstants.email]) &&
            (!props.errors[MembershipFormConstants.mobile] && props.touched[MembershipFormConstants.mobile]) &&
            ((!props.errors[MembershipFormConstants.dateOfBirth] && props.touched[MembershipFormConstants.dateOfBirth]) || props.isRenewal) &&
            ((!props.errors[MembershipFormConstants.occupation] && props.touched[MembershipFormConstants.occupation]) || props.isRenewal) &&
            (!props.errors[MembershipFormConstants.address] && props.touched[MembershipFormConstants.address]) &&
            (!props.errors[MembershipFormConstants.suburb] && props.touched[MembershipFormConstants.suburb]) &&
            (!props.errors[MembershipFormConstants.postcode] && props.touched[MembershipFormConstants.postcode]) &&
            (!props.errors[MembershipFormConstants.state] && props.touched[MembershipFormConstants.state]) &&
            (!props.errors[MembershipFormConstants.pledge] && props.touched[MembershipFormConstants.pledge])) {
            props.setStepNum(2);
        } else {
            touchFields();
        }
    }

    const ctxValue = useContext(ctxt);
    const isIE = ctxValue.isIE;
    return (
        <div className={isIE ? "ieContainer" : "container"}>
            <h2>Personal Details</h2>
            <FormField
                type="text"
                name={MembershipFormConstants.firstName}
                placeholder="First Name"
                errors={props.errors}
                touched={props.touched}
            />
            <FormField
                type="text"
                name={MembershipFormConstants.lastName}
                placeholder="Surname"
                errors={props.errors}
                touched={props.touched}
            />
            <FormField
                type="text"
                name={MembershipFormConstants.email}
                placeholder={MembershipFormConstants.email}
                errors={props.errors}
                touched={props.touched}
            />
            <FormField
                type="text"
                name={MembershipFormConstants.mobile}
                placeholder="Mobile number"
                errors={props.errors}
                touched={props.touched}
            />
            {!props.isRenewal &&
                <div className="col-md-12 my-1 px-4 fieldWrapper">
                    <label className="inputLabel" htmlFor={MembershipFormConstants.dateOfBirth}>
                        Date of birth
                    </label>
                    <DatePicker
                        className={`inputField 
                        ${props.errors[MembershipFormConstants.dateOfBirth] && props.touched[MembershipFormConstants.dateOfBirth]
                                ? "is-invalid"
                                : "is-valid"}
                        `}
                        maxDate={new Date()}
                        value={props.values[MembershipFormConstants.dateOfBirth]}
                        onChange={(date) => {
                            props.setFieldValue(MembershipFormConstants.dateOfBirth, `${moment(date).locale('en-au').format('L')}`);
                            props.setFieldTouched(MembershipFormConstants.dateOfBirth, true, true);
                        }
                        }
                        dropdownMode='select'
                        showYearDropdown
                    />
                    <div
                        style={{
                            visibility:
                                props.errors[MembershipFormConstants.dateOfBirth] &&
                                    props.touched[MembershipFormConstants.dateOfBirth]
                                    ? "visible"
                                    : "hidden"
                        }}
                        className="inputError"
                    >
                        <p className="inputErrorMessage">{props.errors[MembershipFormConstants.dateOfBirth]}</p>
                    </div>
                </div>
            }
            {!props.isRenewal &&
                <FormField
                    type="text"
                    name={MembershipFormConstants.occupation}
                    placeholder={MembershipFormConstants.occupation}
                    errors={props.errors}
                    touched={props.touched}
                />
            }
            <FormField
                type="text"
                name={MembershipFormConstants.address}
                placeholder="Street address"
                errors={props.errors}
                touched={props.touched}
            />
            <FormField
                type="text"
                name={MembershipFormConstants.suburb}
                placeholder={MembershipFormConstants.suburb}
                errors={props.errors}
                touched={props.touched}
            />
            <FormField
                type="text"
                name={MembershipFormConstants.postcode}
                placeholder={MembershipFormConstants.postcode}
                errors={props.errors}
                touched={props.touched}
            />
            <FormField
                type="text"
                name={MembershipFormConstants.state}
                component="select"
                placeholder="State"
                errors={props.errors}
                touched={props.touched}
                children={States.map(
                    (opt: any, index: number) => (
                        <option
                            key={index}
                            value={opt.value}
                        >
                            {opt.text}
                        </option>
                    )
                )}
            />
            <div className="col-md-12 my-1 px-4 indigenousWrapper">
                <p className="info">
                    I am an Australian citizen.
                </p>

                <Field
                    className="radio-group--indigenous"
                    component="div"
                    name={MembershipFormConstants.citizen}
                >
                    <input
                        defaultChecked={false}
                        name={MembershipFormConstants.citizen}
                        type="radio"
                        value="no"
                        className="radio-group--input"
                        id="nee"
                    />
                    <label
                        className="radio-group--label indigenousNo"
                        htmlFor="nee"
                    >
                        No
                    </label>
                    <input
                        defaultChecked={true}
                        name={MembershipFormConstants.citizen}
                        type="radio"
                        value="yes"
                        className="radio-group--input"
                        id="ja"
                    />
                    <label
                        className="radio-group--label"
                        htmlFor="ja"
                    >
                        Yes
                    </label>
                </Field>

            </div>
            {!props.isRenewal &&
                <div className="col-md-12 my-1 px-4 indigenousWrapper">
                    <p className="info">
                        I wish to register as an indigenous person and declare that I meet the provisions of the ATSIC Act as an eligible indigenous person.
                    </p>
                    <Field
                        className="radio-group--indigenous"
                        component="div"
                        name={MembershipFormConstants.indigenous}
                    >
                        <input
                            defaultChecked={true}
                            name={MembershipFormConstants.indigenous}
                            type="radio"
                            value="no"
                            className="radio-group--input"
                            id="no"
                        />
                        <label
                            className="radio-group--label indigenousNo"
                            htmlFor="no"
                        >
                            No
                    </label>
                        <input
                            defaultChecked={false}
                            name={MembershipFormConstants.indigenous}
                            type="radio"
                            value="yes"
                            className="radio-group--input"
                            id="yes"
                        />
                        <label
                            className="radio-group--label"
                            htmlFor="yes"
                        >
                            Yes
                    </label>
                    </Field>

                </div>
            }
            <div className="col-md-12 my-1 px-4 checkboxWrapper">
                <p className="info">
                    By ticking this box I declare my annual earnings are within the range indicated or if paying concession I am entitled to the concessional rate. While a member of the Australian Labor Party (Tasmanian Branch), I agree to be bound by the rules and pledge myself to uphold the platform and to support endorsed Australian Labor Party candidates for public office. I declare that I am not a member of any other political party or otherwise ineligible under the Rules to be a member of the Australian Labor Party.
                    </p>
                <Field
                    className={`singleCheckBox 
                        ${props.errors[MembershipFormConstants.pledge] && props.touched[MembershipFormConstants.pledge]
                            ? "is-invalid"
                            : "is-valid"}
                    `}
                    component="div"
                    name={MembershipFormConstants.pledge}
                >
                    <input
                        defaultChecked={false}
                        name={MembershipFormConstants.pledge}
                        type="checkbox"
                        value="yes"
                        id={MembershipFormConstants.pledge}
                    />
                    <label
                        className="fieldLabel checkboxLabel"
                        htmlFor={MembershipFormConstants.pledge}
                    >
                        I agree
                        </label>
                    <div
                        style={{
                            visibility:
                                props.errors[MembershipFormConstants.pledge] &&
                                    props.touched[MembershipFormConstants.pledge]
                                    ? "visible"
                                    : "hidden"
                        }}
                        className="inputError"
                    >
                        <p className="inputErrorMessage">{props.errors[MembershipFormConstants.pledge]}</p>
                    </div>
                </Field>
            </div>
            <div className="btnGroup">
                <button className="btn" onClick={() => props.setStepNum(0)} type="button">Previous</button>
                <button className="btn" onClick={continueClickHandler} type="button">Continue</button>
            </div>
        </div>
    );
}
