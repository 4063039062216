import * as React from "react";
import { ctxt } from "./TasMembershipForm";
import { useContext } from "react";

interface IStep3Props {
    isRenewal: boolean;
}

export default (props: IStep3Props) => {
    const ctxValue = useContext(ctxt);
    const isIE= ctxValue.isIE;
    return (
        <div className={isIE? "ieContainer": "container"}>
            <h2>{`You successfully ${props.isRenewal ? "renewed" : "joined"}`}</h2>
            <p>A confirmation email will be sent to you</p>
        </div>
    );
}