import * as React from "react";
import { CardNumberElement, CardExpiryElement, CardCVCElement } from "react-stripe-elements";
import { MembershipFormConstants, MembershipTypes } from "./Constants";
import { Field, FormikErrors } from "formik";
import { ctxt, ITasMembershiFormProps } from "./TasMembershipForm";
import { useContext, useEffect } from "react";
import { isEmpty } from "../../../common/helper/helper";


interface IStep2Props {
    animClass: string;
    values: ITasMembershiFormProps;
    setStepNum: (step:number)=>void;
    setSubmitting: (isSubmitting: boolean)=>void;
    stripeCardError: string;
    stripeExpiryError: string;
    stripeCvcError: string;
    setStripeCardError: (errorMsg: string)=>void;
    setStripeExpiryError: (errorMsg: string)=>void;
    setStripeCvcError: (errorMsg: string)=>void;
    stripeCardComplete: boolean;
    stripeExpiryComplete: boolean;
    stripeCvcComplete: boolean;
    setStripeCardComplete: (complete: boolean)=>void;
    setStripeExpiryComplete: (complete: boolean)=>void;
    setStripeCvcComplete: (complete: boolean)=>void;
    submitting: boolean;
    submitError: string;
    errors: FormikErrors<ITasMembershiFormProps>
}


function FormCheck({ label, name }: { label: string, name: string }) {
    return <Field
        className="updateCheck"
        component="div"
        name={name}
    >
        <input
            defaultChecked={false}
            name={name}
            type="checkbox"
            value="yes"
            id={name}
        />
        <label
            className="fieldLabel checkboxLabel"
            htmlFor={name}
        >
            {label}
        </label>
    </Field>
}

function AmountDisplay({ value }: { value: string | number }) {

    return <>{value && Number(value)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,")}</>;

}

export default (props: IStep2Props) => {
    let stripeCardNumHandler = (event: any) => {
        if (event.error && !event.complete) {
            props.setStripeCardError(event.error.message);
        } else if (!event.error && event.complete) {
            props.setStripeCardError("");
        }
        props.setStripeCardComplete(event.complete);
    };
    let stripeExpiryHandler = (event: any) => {
        if (event.error && !event.complete) {
            props.setStripeExpiryError(event.error.message);
        } else if (!event.error && event.complete) {
            props.setStripeExpiryError("");
        }
        props.setStripeExpiryComplete(event.complete);
    };
    let stripeCvcHandler = (event: any) => {
        if (event.error && !event.complete) {
            props.setStripeCvcError(event.error.message);
        } else if (!event.error && event.complete) {
            props.setStripeCvcError("");
        }
        props.setStripeCvcComplete(event.complete);
    };

    let membershipTypeValue = props.values[MembershipFormConstants.membershipType] && props.values[MembershipFormConstants.membershipType] ?
        MembershipTypes.filter(e => e.key == props.values[MembershipFormConstants.membershipType]) ? MembershipTypes.filter(e => e.key == props.values[MembershipFormConstants.membershipType])[0].value : 0
        : 0;
    const ctxValue = useContext(ctxt);
    const isIE = ctxValue.isIE;
    return (
        <div className={isIE ? "ieContainer" : "container"}>
            <h2>Payment</h2>
            <div className="summary">
                <label className="inputLabel">Summary</label>
                <div className="stripeAmount">
                <div>{`Membership cost (${props.values[MembershipFormConstants.yearlyPayment] === "true" || props.values[MembershipFormConstants.yearlyPayment] === true  ? 'Yearly' : 'Monthly'})`}</div>
                    {props.values[MembershipFormConstants.membershipType] && props.values[MembershipFormConstants.membershipType] != "" &&
                        <div>$
                        <AmountDisplay value={membershipTypeValue} />
                    </div>}
                </div>
                <div className="stripeAmount">
                    <div>{`Donation (${props.values[MembershipFormConstants.donationInterval] === 'one' ? 'One-off' : 'Monthly'})`}</div>
                    <div>$ <AmountDisplay value={props.values[MembershipFormConstants.amount]} />

                    </div>
                </div>
            </div>
            <div className="stripeAmount total">
                <label className="inputLabel">TOTAL</label>
                <label className="inputLabel">$
                <AmountDisplay value={(Number(membershipTypeValue) + Number(props.values[MembershipFormConstants.amount]))} />

                </label>
            </div>
            <label className="inputLabel">
                Credit card number
            </label>
            <CardNumberElement
                className={`inputField
                                ${props.stripeCardError
                        ? "is-invalid"
                        : "is-valid"}
                            `}
                onChange={stripeCardNumHandler}
            />
            {props.stripeCardError ? (
                <div className="inputErrorMessage">{props.stripeCardError}</div>
            ) : (
                    <div className="inputErrorMessage" />
                )}
            <div className="stripeExpCvc">
                <div className="stripeField">
                    <label className="inputLabel">
                        Expiry date
                    </label>
                    <CardExpiryElement
                        className={`inputField
                                ${props.stripeExpiryError
                                ? "is-invalid"
                                : "is-valid"}
                            `}
                        onChange={stripeExpiryHandler}
                    />
                    {props.stripeExpiryError ? (
                        <div className="inputErrorMessage">{props.stripeExpiryError}</div>
                    ) : (
                            <div className="inputErrorMessage" />
                        )}
                </div>
                <div className="stripeField">
                    <label className="inputLabel">
                        CVC
                    </label>
                    <CardCVCElement
                        className={`inputField
                                ${props.stripeCvcError
                                ? "is-invalid"
                                : "is-valid"}
                            `}
                        onChange={stripeCvcHandler}
                    />
                    {props.stripeCvcError ? (
                        <div className="inputErrorMessage">{props.stripeCvcError}</div>
                    ) : (
                            <div className="inputErrorMessage" />
                        )}
                </div>
            </div>
            <label className="inputLabel updatesText">
                I would like to join the following Policy Action Caucuses:
            </label>
            <div className="updates inputField">
                <FormCheck name={MembershipFormConstants.interestedInRainbow} label="Rainbow Labor" />
                <FormCheck name={MembershipFormConstants.interestedInLEAN} label="Labor Environment Action Network (LEAN)" />
                <FormCheck name={MembershipFormConstants.interestedInEnabled} label="Labor Enabled" />
                <FormCheck name={MembershipFormConstants.interestedInForChoice} label="Labor for Choice" />
                <FormCheck name={MembershipFormConstants.interestedInPalestine} label="Labor for Palestine" />
                <FormCheck name={MembershipFormConstants.interestedInWiseUseOfResources} label="Labor for Wise Use of Resources" />
                <FormCheck name={MembershipFormConstants.interestedInRegionalTas} label="Labor for Regional Tasmania" />
                <FormCheck name={MembershipFormConstants.interestedInLocalGovernment} label="Local Government" />
                <FormCheck name={MembershipFormConstants.interestedInCivilPoliticalRights} label="Labor for Civil & Political Rights" />
                <FormCheck name={MembershipFormConstants.interestedInTradeUnion} label="Trade Union" />

            </div>
            <div className="btnGroup">
                <button className="btn" onClick={() => props.setStepNum(1)} type="button">Previous</button>
                <button className="btn" type={!isEmpty(props.errors)? "button" : "submit"}>Submit Payment</button>
            </div>
            <div className="errorMessage">
                {props.submitError}
            </div>
        </div>
    );
}
