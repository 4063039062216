import * as Yup from "yup";
import { MembershipFormConstants } from "./Constants";
import { formFieldRex, fieldErrorMessage } from "../../GenericForm/Components/FunctionLib";
import { MembershipFormType } from "../../WaMembershipForm/Components/Constants";

export const TasMembershipValidationSchemaFactory = (membershipFormType: MembershipFormType) => {
    const getValidationSchema = () => {
        switch (membershipFormType) {
            case MembershipFormType.Join:
                return { ...TasMembershipValidationSchemaBase, ...DobValidationSchema }
            case MembershipFormType.Renew:
                return TasMembershipValidationSchemaBase
        }
    }
    return Yup.object().shape(getValidationSchema())
}

const errorMsg = {
    reuired: "This field is required",
    email: "This field must be a valid email address",
    phone: "This field must be a valid phone number",
    postcode: "This field must be a valid postcode",
    noNumAllowed: "No numbers allowed",
    date: "This field must be a valid date"
}

const TasMembershipValidationSchemaBase = {
    [MembershipFormConstants.firstName]: Yup.string()
        .required(errorMsg.reuired)
        .matches(formFieldRex.nameRegExp, fieldErrorMessage.nameFieldError)
        .max(40),
    [MembershipFormConstants.lastName]: Yup.string()
        .required(errorMsg.reuired)
        .matches(formFieldRex.nameRegExp, fieldErrorMessage.nameFieldError)
        .max(40),
    [MembershipFormConstants.email]: Yup.string()
        .required(errorMsg.reuired)
        .email(errorMsg.email)
        .max(50),
    [MembershipFormConstants.mobile]: Yup.string()
        .required(errorMsg.reuired)
        .matches(formFieldRex.phoneRegExp, errorMsg.phone)
        .max(50),
    [MembershipFormConstants.address]: Yup.string()
        .required(errorMsg.reuired)
        .max(50),
    [MembershipFormConstants.suburb]: Yup.string()
        .required(errorMsg.reuired)
        .matches(formFieldRex.noNumbersRegExp, errorMsg.noNumAllowed)
        .max(50),
    [MembershipFormConstants.postcode]: Yup.string()
        .required(errorMsg.reuired)
        .matches(formFieldRex.postcodeRegExp, errorMsg.postcode)
        .max(4),
    [MembershipFormConstants.state]: Yup.string()
        .required(errorMsg.reuired),
    [MembershipFormConstants.pledge]: Yup.bool()
        .oneOf([true], errorMsg.reuired),
    [MembershipFormConstants.membershipType]: Yup.string()
        .required(errorMsg.reuired).trim(errorMsg.reuired)
}

const DobValidationSchema = {
    [MembershipFormConstants.dateOfBirth]: Yup.string()
        .required(errorMsg.reuired)
        .matches(formFieldRex.dateRegExp, errorMsg.date),
    [MembershipFormConstants.occupation]: Yup.string()
        .required(errorMsg.reuired)
        .max(50)
}