import * as React from 'react';

interface IProgressBarProps {
    stepNumber: number;
}

export default (props: IProgressBarProps) => {

    let setProgress = (step: number, stepNum: number) => {
        if (step > stepNum) {
            return "step";
        } else {
            return "step stepCompleted";
        }
    }
    let setProgressBar = (step: number, stepNum: number) => {
        if (step < stepNum) {
            return "bar barCompleted";
        } else {
            return "bar";
        }
    }
    return (

        <div className="donSteps container">
            <div className="stepWrapper">
                <div className={setProgress(0, props.stepNumber)}>1</div>
            </div>
            <hr className={setProgressBar(0, props.stepNumber)} />
            <div className="stepWrapper">
                <div className={setProgress(1, props.stepNumber)}>2</div>
            </div>
            <hr className={setProgressBar(1, props.stepNumber)} />
            <div className="stepWrapper">
                <div className={setProgress(2, props.stepNumber)}>3</div>
            </div>
        </div>
    );
}
