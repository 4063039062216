import * as React from "react";
import { Field, FormikTouched, FormikErrors } from "formik";
import { MembershipFormConstants, MembershipTypes, Unions, ITasMembershipFormConstants } from './Constants';
import FormField from './FormField';
import { useState, useContext } from 'react';
import { ctxt } from "./TasMembershipForm";

interface IStep0Props {
    animClass: string;
    values: ITasMembershipFormConstants;
    setFieldValue: (field: keyof ITasMembershipFormConstants & string, value: any, shouldValidate?: boolean) => void;
    setFieldTouched(field: keyof ITasMembershipFormConstants & string, isTouched?: boolean, shouldValidate?: boolean): void;
    touched: FormikTouched<ITasMembershipFormConstants>;
    errors: FormikErrors<ITasMembershipFormConstants>;
    setStepNum: (step: number) => void;
    isRenewal: boolean;
}
export default (props: IStep0Props) => {

    const [yearlyPayment, setPaymentType] = useState(false);
    const [unionRequired, setUnionRequired] = useState(false);
    const [unionSelected, setUnionSelected] = useState(false);
    const [unionClassName, setUnionClassName] = useState("");
    const [unionErrorMessage, setUnionErrorMessage] = useState("");

    let continueClickHandler = () => {
        if ((!unionRequired || (unionSelected && unionRequired)) && !props.errors[MembershipFormConstants.membershipType] && props.touched[MembershipFormConstants.membershipType])  {
            setUnionClassName("is-valid");
            setUnionErrorMessage("");
            props.setStepNum(1);
        } else if (unionRequired && !unionSelected) {
            setUnionClassName("is-invalid");
            setUnionErrorMessage("Please select your affiliated union");
        } else if (props.errors[MembershipFormConstants.membershipType] || !props.touched[MembershipFormConstants.membershipType]) {
            props.setFieldTouched("membershipType", true, true)
        }
    }

    const OnChangePaymentType = (e: any, setFieldValue: any, values: any) => {
        if (e.target.value === "true") {
            setPaymentType(true);
            setFieldValue(MembershipFormConstants.membershipType, "");
        } else if (e.target.value === "false") {
            setPaymentType(false);
            setFieldValue(MembershipFormConstants.membershipType, "");
        }
        setFieldValue(MembershipFormConstants.yearlyPayment, e.target.value);
        setUnionRequired(false);
        setFieldValue(MembershipFormConstants.union, "");
    }
    const onChangeUnion = (e: any, setFieldValue: any) => {
        e.target.value !== "" ? (setUnionSelected(true), setUnionClassName("is-valid"), setUnionErrorMessage("")) : setUnionSelected(false);
        setFieldValue(MembershipFormConstants.union, e.target.value);
    }
    const onChangeMembershipType = (e: any, setFieldValue: any) => {
        if (e.target.value.match(/Union/g)) {
            setUnionRequired(true)
            if(unionSelected)
            {
                setUnionSelected(false)
            }
        } else {
            setUnionRequired(false);
        }
        setFieldValue(MembershipFormConstants.membershipType, e.target.value);
        setFieldValue(MembershipFormConstants.union, "");
    }
    const ctxValue = useContext(ctxt);
    const isIE = ctxValue.isIE;
    return (
        <div className={isIE ? "ieContainer" : "container"}>
            <div className="row">
                <h2>{`Membership${props.isRenewal ? " Renewal" : ""}`}</h2>

                <div className="radio-group col-md-12 my-1 px-4">
                    <div className="col-md-12 my-1 px-4 fieldWrapper">
                        <label
                            className="inputLabel"
                            htmlFor={MembershipFormConstants.yearlyPayment}>
                            Payment Frequency
                        </label>
                        <Field
                            className={`inputField
                                ${props.errors[MembershipFormConstants.yearlyPayment] && props.touched[MembershipFormConstants.yearlyPayment]
                                    ? "is-invalid"
                                    : "is-valid"}
                                `}
                            type="text"
                            name={MembershipFormConstants.yearlyPayment}
                            component="select"
                            onChange={(e: any) => OnChangePaymentType(e, props.setFieldValue, props.values)}
                        >
                            [<option
                                key={1}
                                value="false"
                            >
                                Monthly
                            </option>,
                            <option
                                key={2}
                                value="true"
                            >
                                Yearly
                            </option>]
                        </Field>
                        <div
                            style={{
                                opacity:
                                    props.errors[MembershipFormConstants.yearlyPayment] &&
                                        props.touched[MembershipFormConstants.yearlyPayment]
                                        ? 1
                                        : 0
                            }}
                            className="inputError"
                        >
                            <p className="inputErrorMessage">{props.errors[MembershipFormConstants.yearlyPayment]}</p>
                        </div>
                    </div>
                </div>

                <div className="radio-group col-md-12 my-1 px-4">
                    <div className="col-md-12 my-1 px-4 fieldWrapper">
                        <label
                            className="inputLabel"
                            htmlFor={MembershipFormConstants.membershipType}
                        >
                            Membership Type
                            </label>
                        <Field
                            className={`inputField
                            ${props.errors[MembershipFormConstants.membershipType] && props.touched[MembershipFormConstants.membershipType]
                                    ? "is-invalid"
                                    : "is-valid"}`}
                            type="text"
                            name={MembershipFormConstants.membershipType}
                            component="select"
                            onChange={(e: any) => onChangeMembershipType(e, props.setFieldValue)}
                        >
                            {(yearlyPayment ? MembershipTypes.filter(e => e.key == "" || e.key.startsWith("Y-")) : MembershipTypes.filter(e => e.key == "" || e.key.startsWith("M-"))).map(
                                (opt: any, index: number) => (
                                    <option
                                        key={index}
                                        value={opt.key}
                                    >
                                        {opt.text}
                                    </option>
                                )
                            )}
                        </Field>
                        <p className="inputErrorMessage">{props.errors[MembershipFormConstants.membershipType]}</p>
                        <div
                            style={{
                                opacity:
                                    props.errors[MembershipFormConstants.union] &&
                                        props.touched[MembershipFormConstants.union]
                                        ? 1
                                        : 0
                            }}
                            className="inputError"
                        >
                            <p className="inputErrorMessage">{props.errors[MembershipFormConstants.union]}</p>
                        </div>
                    </div>
                </div>
                {unionRequired &&
                    <div className="radio-group col-md-12 my-1 px-4">
                        <div className="col-md-12 my-1 px-4 fieldWrapper">
                            <label
                                className="inputLabel"
                                htmlFor={MembershipFormConstants.union}
                            >
                                Which affiliated union are you a member of? (Your union must be specified in order to receive a union discount)
                            </label>
                            <Field
                                className={`inputField ${unionClassName}`}
                                type="text"
                                name={MembershipFormConstants.union}
                                component="select"
                                onChange={(e: any) => onChangeUnion(e, props.setFieldValue)}
                            >
                                {Unions.map(
                                    (opt: any, index: number) => (
                                        <option
                                            key={index}
                                            value={opt.value}
                                        >
                                            {opt.text}
                                        </option>
                                    )
                                )}
                            </Field>
                            <div
                                className="inputError"
                            >
                                <p className="inputErrorMessage">{unionErrorMessage}</p>
                            </div>
                        </div>
                    </div>}
                <div className="radio-group col-md-12 my-1 px-4">
                    <label className="inputLabel" htmlFor={MembershipFormConstants.amount}>
                        Your donation (please choose one of the following)
                    </label>
                    <Field
                        className="radio-group--controls"
                        component="div"
                        name={MembershipFormConstants.amount}
                    >
                        <input
                            defaultChecked={true}
                            name={MembershipFormConstants.amount}
                            type="radio"
                            value="5"
                            className="radio-group--input"
                            id="5"
                        />
                        <label
                            className="radio-group--label"
                            htmlFor="5"
                        >
                            $5
                        </label>
                        <input
                            defaultChecked={false}
                            name={MembershipFormConstants.amount}
                            type="radio"
                            value="10"
                            className="radio-group--input"
                            id="10"
                        />
                        <label
                            className="radio-group--label"
                            htmlFor="10"
                        >
                            $10
                        </label>
                        <input
                            defaultChecked={false}
                            name={MembershipFormConstants.amount}
                            type="radio"
                            value="25"
                            className="radio-group--input"
                            id="25"
                        />
                        <label
                            className="radio-group--label"
                            htmlFor="25"
                        >
                            $25
                        </label>
                        <input
                            defaultChecked={false}
                            name={MembershipFormConstants.amount}
                            type="radio"
                            value="50"
                            className="radio-group--input"
                            id="50"
                        />
                        <label
                            className="radio-group--label"
                            htmlFor="50"
                        >
                            $50
                        </label>
                        <input
                            defaultChecked={false}
                            name={MembershipFormConstants.amount}
                            type="radio"
                            value="100"
                            className="radio-group--input"
                            id="100"
                        />
                        <label
                            className="radio-group--label"
                            htmlFor="100"
                        >
                            $100
                        </label>
                        <input
                            defaultChecked={false}
                            name={MembershipFormConstants.amount}
                            type="radio"
                            value="0"
                            className="radio-group--input"
                            id="0"
                        />
                        <label
                            className="radio-group--label"
                            htmlFor="0"
                        >
                            $0
                        </label>
                    </Field>
                </div>
                <div className="radio-group col-md-12 my-1 px-4">
                    <div className="col-md-12 my-1 px-4 fieldWrapper">
                        <label
                            className="inputLabel"
                            htmlFor={MembershipFormConstants.donationInterval}>
                            Donation Frequency
                        </label>
                        <Field
                            className="inputField"
                            type="text"
                            name={MembershipFormConstants.donationInterval}
                            component="select"
                        >
                            [<option
                                key={1}
                                value="one"
                            >
                                One-off
                            </option>,
                            <option
                                key={2}
                                value="monthly"
                            >
                                Monthly
                            </option>]
                        </Field>
                    </div>
                </div>
                {!props.isRenewal &&
                    <div className="radio-group col-md-12 my-1 px-4">
                        <FormField
                            type="text"
                            name={MembershipFormConstants.referral}
                            placeholder="If you found out about joining the Labor Party through another member, please enter their name here"
                            errors={props.errors}
                            touched={props.touched}
                        />
                    </div>
                }
                <div className="btnGroup col-md-12 my-1 px-4">
                    <button className="btn" onClick={continueClickHandler} type="button">Continue</button>
                </div>
            </div>
        </div>
    );
}
