import * as React from "react";
import { Field } from "formik";

interface IFormFieldProps {
    name: string;
    placeholder: string;
    component?: string;
    type: string;
    errors: any;
    touched: any;
    children?: string | JSX.Element | JSX.Element[];
}

export default (props: IFormFieldProps) => {
    return (
        <div className="col-md-12 my-1 px-4 fieldWrapper">
            <label
                className="inputLabel"
                htmlFor={props.name}>
                {props.placeholder}
            </label>
            <Field
                className={`inputField
                    ${props.errors[props.name] && props.touched[props.name]
                        ? "is-invalid"
                        : "is-valid"}
                `}
                type={props.type}
                name={props.name}
                component={props.component}
            >
                {props.children}
            </Field>
            <div
                style={{
                    opacity:
                        props.errors[props.name] &&
                            props.touched[props.name]
                            ? 1
                            : 0
                }}
                className="inputError"
            >
                <p className="inputErrorMessage">{props.errors[props.name]}</p>
            </div>
        </div>
    );
}
