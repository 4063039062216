import { IReCaptCha } from "components/GoogleReCaptcha/Recaptcha";

export interface IMemberShipFormProps extends IReCaptCha {
	contentId: string;
	publishableKey: string;
	isIE: boolean;
}

export enum MembershipFormType {
	Join = "join",
	Renew = "renew",
	Update = "update",
}

export interface IWaMemberShipFormModel {
	membershipNumber: string;
	firstName: string;
	lastName: string;
	dateOfBirth: string;
	gender: string;
	email: string;
	phone: string;
	electoralRoll: string;
	aboriginalDescent: string;
	occupation: string;
	nominatedBranch: string;
	address: string;
	suburb: string;
	state: string;
	postcode: string;
	sameAsResidential: string;
	postalAddress: string;
	postalSuburb: string;
	postalState: string;
	postalPostcode: string;
	annualIncome: string;
	concession: boolean;
	union: string;
	application: boolean;
	firstTimeMember: string;
	previousYearMembership: string;
	declaration: boolean;
	signature: any;
	adminComRef: boolean;
	adminComRefDetails: string;
	monthlyPayment: Number;
	yearlyPayment: Number;
}

export const WaFormFieldConstants = {
	membershipNumber: "MembershipNumber",
	firstName: "FirstName",
	lastName: "LastName",
	dateOfBirth: "DateOfBirth",
	gender: "Gender",
	email: "Email",
	phone: "Phone",
	electoralRoll: "ElectoralRoll",
	aboriginalDescent: "AboriginalDescent",
	occupation: "Occupation",
	nominatedBranch: "NominatedBranch",
	address: "Address",
	suburb: "Suburb",
	state: "State",
	postcode: "Postcode",
	sameAsResidential: "SameAsResidential",
	postalAddress: "PostalAddress",
	postalSuburb: "PostalSuburb",
	postalState: "PostalState",
	postalPostcode: "PostalPostcode",
	annualIncome: "AnnualIncome",
	concession: "Concession",
	union: "Union",
	application: "Application",
	firstTimeMember: "FirstTimeMember",
	previousYearMembership: "PreviousYearMembership",
	declaration: "Declaration",
	signature: "Signature",
	adminComRef: "AdminComRef",
	adminComRef1: "AdminComRef1",
	adminComRef2: "AdminComRef2",
	adminComRef3: "AdminComRef3",
	adminComRef4: "AdminComRef4",
	adminComRef5: "AdminComRef5",
	adminComRef6: "AdminComRef6",
	adminComRefDetails: "AdminComRefDetails",
	monthlyPayment: "MonthlyPayment",
	yearlyPayment: "YearlyPayment",
};

export interface IFormSelectOption {
	value: string;
	text: string;
}

export const States: IFormSelectOption[] = [
	{
		value: "",
		text: "Select State",
	},
	{
		value: "NSW",
		text: "New South Wales",
	},
	{
		value: "VIC",
		text: "Victoria",
	},
	{
		value: "QLD",
		text: "Queensland",
	},
	{
		value: "SA",
		text: "South Australia",
	},
	{
		value: "WA",
		text: "Western Australia",
	},
	{
		value: "TAS",
		text: "Tasmania",
	},
	{
		value: "NT",
		text: "Northern Territory",
	},
	{
		value: "ACT",
		text: "Australian Capital Territory",
	},
];

export const Gender: IFormSelectOption[] = [
	{ value: "", text: "-Select Gender-" },
	{ value: "M", text: "Male" },
	{ value: "F", text: "Female" },
	{ value: "O", text: "Other" },
	{ value: "P", text: "Prefer not to say" },
];

export const WaMembershipInitialValuesFactory = (
	formType: MembershipFormType
): IWaMemberShipFormModel => {
	switch (formType) {
		case MembershipFormType.Update:
			return {
				...MembershipInitialValuesBase,
				[WaFormFieldConstants.nominatedBranch]: "",
			};
		default:
			return {
				...MembershipInitialValuesBase,
				[WaFormFieldConstants.nominatedBranch]: "",
			};
	}
};

const MembershipInitialValuesBase: any = {
	[WaFormFieldConstants.membershipNumber]: "",
	[WaFormFieldConstants.firstName]: "",
	[WaFormFieldConstants.lastName]: "",
	[WaFormFieldConstants.dateOfBirth]: "",
	[WaFormFieldConstants.gender]: "",
	[WaFormFieldConstants.email]: "",
	[WaFormFieldConstants.phone]: "",
	[WaFormFieldConstants.electoralRoll]: "",
	[WaFormFieldConstants.aboriginalDescent]: "",
	[WaFormFieldConstants.occupation]: "",
	[WaFormFieldConstants.address]: "",
	[WaFormFieldConstants.suburb]: "",
	[WaFormFieldConstants.state]: "",
	[WaFormFieldConstants.postcode]: "",
	[WaFormFieldConstants.sameAsResidential]: "",
	[WaFormFieldConstants.postalAddress]: "",
	[WaFormFieldConstants.postalSuburb]: "",
	[WaFormFieldConstants.postalState]: "",
	[WaFormFieldConstants.postalPostcode]: "",
	[WaFormFieldConstants.annualIncome]: "",
	[WaFormFieldConstants.concession]: false,
	[WaFormFieldConstants.union]: "",
	[WaFormFieldConstants.application]: false,
	[WaFormFieldConstants.firstTimeMember]: "",
	[WaFormFieldConstants.previousYearMembership]: "",
	[WaFormFieldConstants.declaration]: false,
	[WaFormFieldConstants.adminComRef]: false,
	[WaFormFieldConstants.adminComRef1]: false,
	[WaFormFieldConstants.adminComRef2]: false,
	[WaFormFieldConstants.adminComRef3]: false,
	[WaFormFieldConstants.adminComRef4]: false,
	[WaFormFieldConstants.adminComRef5]: false,
	[WaFormFieldConstants.adminComRef6]: false,
	[WaFormFieldConstants.adminComRefDetails]: "",
	[WaFormFieldConstants.signature]: "",
	[WaFormFieldConstants.monthlyPayment]: 0,
	[WaFormFieldConstants.yearlyPayment]: 0,
	[WaFormFieldConstants.nominatedBranch]: "",
};
