import * as React from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { ClipLoader } from 'react-spinners';
import { sanitize } from 'dompurify';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { injectStripe, ReactStripeElements } from 'react-stripe-elements';
import { Formik, Form } from 'formik';
import { TasMembershipValidationSchemaFactory } from './FunctionLib';
import { MembershipFormConstants, MembershipTypes, TasMembershipInitialValuesFactory } from './Constants';
import ProgressBar from './ProgressBar';
import Step0 from './Step0';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import { getRcTokenAsync, tokenFailable } from '../../GoogleReCaptcha/Recaptcha';
import { isEmpty } from '../../../common/helper/helper';
import '../Style/tasMembershipForm.scss';
import { IMemberShipFormProps, MembershipFormType } from '../../WaMembershipForm/Components/Constants';
export interface ITasMembershiFormProps extends IMemberShipFormProps {
    stripe?: ReactStripeElements.StripeProps
    isRenewal: boolean

}

interface AppContextInterface {
    isIE: boolean
}

interface IPaymentResponse {
    Message: string
    ReceiptNumber: string
    Success: boolean
}

export const ctxt = React.createContext<AppContextInterface>({ isIE: false });

export const TasFormContextProvider = ctxt.Provider;

export const TasFormContextConsumer = ctxt.Consumer;

function TasMembershipForm(props: ITasMembershiFormProps) {

    const { executeRecaptcha } = useGoogleReCaptcha();

    const submitFunction = async (values: any, actions: any) => {
        const rcToken = getRcTokenAsync("tasMembership", executeRecaptcha);
        setSubmitting(true);
        if (stripeCardComplete &&
            stripeExpiryComplete &&
            stripeCvcComplete &&
            !stripeCardError &&
            !stripeExpiryError &&
            !stripeCvcError) {
            let fixedValues: Partial<ITasMembershiFormProps> = {};

            fixedValues[MembershipFormConstants.membershipType] = values[MembershipFormConstants.membershipType];
            fixedValues[MembershipFormConstants.amount] = values[MembershipFormConstants.amount];
            fixedValues[MembershipFormConstants.membershipCost] = MembershipTypes.filter(e => e.key == values[MembershipFormConstants.membershipType]) ? MembershipTypes.filter(e => e.key == values[MembershipFormConstants.membershipType])[0].value : 0;
            fixedValues[MembershipFormConstants.interestedInRainbow] = values[MembershipFormConstants.interestedInRainbow];
            fixedValues[MembershipFormConstants.interestedInLEAN] = values[MembershipFormConstants.interestedInLEAN];
            fixedValues[MembershipFormConstants.interestedInEnabled] = values[MembershipFormConstants.interestedInEnabled];
            fixedValues[MembershipFormConstants.interestedInForChoice] = values[MembershipFormConstants.interestedInForChoice];
            fixedValues[MembershipFormConstants.interestedInPalestine] = values[MembershipFormConstants.interestedInPalestine];
            fixedValues[MembershipFormConstants.interestedInWiseUseOfResources] = values[MembershipFormConstants.interestedInWiseUseOfResources];
            fixedValues[MembershipFormConstants.interestedInRegionalTas] = values[MembershipFormConstants.interestedInRegionalTas];
            fixedValues[MembershipFormConstants.interestedInLocalGovernment] = values[MembershipFormConstants.interestedInLocalGovernment];
            fixedValues[MembershipFormConstants.interestedInCivilPoliticalRights] = values[MembershipFormConstants.interestedInCivilPoliticalRights];
            fixedValues[MembershipFormConstants.interestedInTradeUnion] = values[MembershipFormConstants.interestedInTradeUnion];
            fixedValues[MembershipFormConstants.pledge] = values[MembershipFormConstants.pledge];
            if (values[MembershipFormConstants.citizen] === "yes") {
                fixedValues[MembershipFormConstants.citizen] = true;
            } else {
                fixedValues[MembershipFormConstants.citizen] = false;
            }
            if (typeof values[MembershipFormConstants.yearlyPayment] !== "boolean") {
                if (values[MembershipFormConstants.yearlyPayment] === "true") {
                    fixedValues[MembershipFormConstants.yearlyPayment] = true;
                } else {
                    fixedValues[MembershipFormConstants.yearlyPayment] = false;
                }
            } else {
                fixedValues[MembershipFormConstants.yearlyPayment] = values[MembershipFormConstants.yearlyPayment];
            }
            if (!props.isRenewal) {
                if (values[MembershipFormConstants.indigenous] === "yes") {
                    fixedValues[MembershipFormConstants.indigenous] = true;
                } else {
                    fixedValues[MembershipFormConstants.indigenous] = false;
                }
            }
            if (values[MembershipFormConstants.donationInterval] === "monthly") {
                fixedValues[MembershipFormConstants.donationInterval] = 2;
            }
            fixedValues[MembershipFormConstants.renewal] = props.isRenewal;
            Object.entries(values).forEach(([key, value]) => {
                if (key !== MembershipFormConstants.yearlyPayment &&
                    key !== MembershipFormConstants.amount &&
                    key !== MembershipFormConstants.membershipType &&
                    key !== MembershipFormConstants.citizen &&
                    key !== MembershipFormConstants.indigenous &&
                    key !== MembershipFormConstants.interestedInRainbow &&
                    key !== MembershipFormConstants.interestedInLEAN &&
                    key !== MembershipFormConstants.interestedInEnabled &&
                    key !== MembershipFormConstants.interestedInForChoice &&
                    key !== MembershipFormConstants.interestedInPalestine &&
                    key !== MembershipFormConstants.interestedInWiseUseOfResources &&
                    key !== MembershipFormConstants.interestedInRegionalTas &&
                    key !== MembershipFormConstants.interestedInLocalGovernment &&
                    key !== MembershipFormConstants.interestedInCivilPoliticalRights &&
                    key !== MembershipFormConstants.pledge &&
                    key !== MembershipFormConstants.donationInterval &&
                    key !== MembershipFormConstants.interestedInTradeUnion) {
                    fixedValues[key] = sanitize(values[key].trim(), { ALLOWED_TAGS: [] });
                }
            });
            const GetToken = async () => {
                try {
                    return await props.stripe.createToken({
                        name: `${values[MembershipFormConstants.firstName]} ${
                            values[MembershipFormConstants.lastName]
                            }`
                    });
                } catch (error) {
                    setSubmitting(false)
                    setSubmitError("Payment Delined")
                    setIsLoading(false);
                }
            }

            const { token, error } = await GetToken();
            if (error != null && !isEmpty(error)) {
                setSubmitting(false);
                setIsLoading(false);
                if (error.message) {
                    setSubmitError(error.message)
                } else if (error.decline_code) {
                    setSubmitError(error.decline_code)
                } else {
                    setSubmitError("Generating token failed and please try again later.")
                }
            } else if (!token || !token.id) {
                setSubmitError("Generating token failed and please try again later.")
            } else {
                const ContentId = parseInt(props.contentId, 10);
                const body = {
                    ...fixedValues,
                    ContentId: ContentId,
                    SecureToken: token.id,
                    RcToken: await tokenFailable(rcToken)
                };
                setIsLoading(true);
                fetch("/Umbraco/Api/TasMembership/Submit", {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(body)
                }).then(async resp => {
                    if (!resp.ok) {
                        setSubmitError("Sorry an unexpected error occurred, your payment MAY have been taken so please contact us.");
                        setSubmitting(false);
                        setIsLoading(false);
                    } else {
                        var data = await resp.json() as IPaymentResponse
                        if (!data.Success) {
                            setSubmitError(data.Message);
                            setSubmitting(false);
                            setIsLoading(false);
                        } else {
                            setIsLoading(false);
                            setStepNum(3);
                        }
                    }
                }).catch((error) => {
                    setSubmitError(error);
                    setSubmitting(false);
                    setIsLoading(false);
                });
            }

        } else {
            if (!stripeCardComplete) {
                setStripeCardError("Card number is required");
            }
            if (!stripeExpiryComplete) {
                setStripeExpiryError("Card expiry date is required");
            }
            if (!stripeCvcComplete) {
                setStripeCvcError("Card CVC is required");
            }
        }
    };
    const setStepNum = (step: number) => {
        switch (step) {
            case 0:
                if (stepNum === 0) {
                    setStep1Class("outRight");
                    setStep0Class("in");
                } else if (stepNum === 1) {
                    setStep0Class("in");
                    setStep1Class("outRight");
                }
                break;
            case 1:
                if (stepNum === 0) {
                    setStep0Class("outLeft");
                    setStep1Class("in");
                } else if (stepNum === 2) {
                    setStep1Class("in");
                    setStep2Class("outRight");
                }
                break;
            case 2:
                setStep1Class("outLeft");
                setStep2Class("in");
                break;
            case 3:
                setStep2Class("outLeft");
                setStep3Class("in");
        }
        setTimeout(() => setStepNumber(step), 200);
    };
    const [stepNum, setStepNumber] = React.useState(0);
    const [step0Class, setStep0Class] = React.useState("in");
    const [step1Class, setStep1Class] = React.useState("outRight");
    const [step2Class, setStep2Class] = React.useState("outRight");
    const [step3Class, setStep3Class] = React.useState("outRight");
    const [stripeCardError, setStripeCardError] = React.useState("");
    const [stripeExpiryError, setStripeExpiryError] = React.useState("");
    const [stripeCvcError, setStripeCvcError] = React.useState("");
    const [stripeCardComplete, setStripeCardComplete] = React.useState(false);
    const [stripeExpiryComplete, setStripeExpiryComplete] = React.useState(false);
    const [stripeCvcComplete, setStripeCvcComplete] = React.useState(false);
    const [submitting, setSubmitting] = React.useState(false);
    const [submitError, setSubmitError] = React.useState<string>("");
    const [isLoading, setIsLoading] = React.useState(false);
    return (
        <TasFormContextProvider value={{ isIE: props.isIE }}>
            <Formik
                initialValues={props.isRenewal ? TasMembershipInitialValuesFactory(MembershipFormType.Renew) : TasMembershipInitialValuesFactory(MembershipFormType.Join)}
                validationSchema={props.isRenewal ? TasMembershipValidationSchemaFactory(MembershipFormType.Renew) : TasMembershipValidationSchemaFactory(MembershipFormType.Join)}
                onSubmit={submitFunction}
                render={({ errors, touched, values, setFieldValue, setFieldTouched, setSubmitting }) => (
                    <LoadingOverlay
                        active={isLoading}
                        className={"loadingOverlay"}
                        spinner={<ClipLoader
                            sizeUnit={"px"}
                            size={200}
                            color={'white'}
                        />}>
                        <Form>
                            <ProgressBar stepNumber={stepNum} />
                            <div className="rootContainerThing">
                                <div className={step0Class}>
                                    <Step0
                                        setFieldValue={setFieldValue}
                                        setFieldTouched={setFieldTouched}
                                        animClass={step0Class}
                                        values={values}
                                        errors={errors}
                                        touched={touched}
                                        setStepNum={setStepNum}
                                        isRenewal={props.isRenewal}
                                    />
                                </div>
                                <div className={step1Class}>
                                    <Step1
                                        setFieldValue={setFieldValue}
                                        animClass={step1Class}
                                        values={values}
                                        errors={errors}
                                        touched={touched}
                                        setStepNum={setStepNum}
                                        setFieldTouched={setFieldTouched}
                                        isRenewal={props.isRenewal}
                                    />
                                </div>
                                <div className={step2Class}>
                                    <Step2
                                        animClass={step2Class}
                                        values={values}
                                        setStepNum={setStepNum}
                                        setSubmitting={setSubmitting}
                                        stripeCardError={stripeCardError}
                                        stripeExpiryError={stripeExpiryError}
                                        stripeCvcError={stripeCvcError}
                                        setStripeCardError={setStripeCardError}
                                        setStripeExpiryError={setStripeExpiryError}
                                        setStripeCvcError={setStripeCvcError}
                                        stripeCardComplete={stripeCardComplete}
                                        stripeExpiryComplete={stripeExpiryComplete}
                                        stripeCvcComplete={stripeCvcComplete}
                                        setStripeCardComplete={setStripeCardComplete}
                                        setStripeExpiryComplete={setStripeExpiryComplete}
                                        setStripeCvcComplete={setStripeCvcComplete}
                                        submitting={submitting}
                                        submitError={submitError}
                                        errors={errors}
                                    />
                                </div>
                                <div className={step3Class}>
                                    <Step3
                                        isRenewal={props.isRenewal}
                                    />
                                </div>
                            </div>
                        </Form>
                    </LoadingOverlay>
                )}
            />
        </TasFormContextProvider>
    );
}
export default injectStripe(TasMembershipForm);